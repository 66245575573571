function App() {
  return (
    <div className='flex flex-col justify-around items-center text-slate-200 font-extralight tracking-wide h-full'>
      <div className="flex flex-row justify-center text-7xl">
        NATSPARK
      </div>
      <div className='flex flex-col items-center justify-center text-xl'>
        <p className='pb-8'>Regenerative strategy done the right way.</p>
        <p className='text-base hover:font-normal'>contact@natspark.net</p>  
      </div>
    </div>
  );
}

export default App;
